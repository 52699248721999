import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import BlockchainApiManager from "../app/services/blockchainApiManager";

function Block() {
  let params = useParams();

  const {
    isLoading,
    error,
    data: transaction,
  } = useQuery(
    [`transaction`, params.id],
    () => BlockchainApiManager.getTransaction(params.id),
    {}
  );

  return (
    <div>
      <h4>Transaction</h4>
      {isLoading ? <Skeleton /> : null}
      {error ? <p>An error occured</p> : null}
      {transaction ? <>{JSON.stringify(transaction)}</> : null}
    </div>
  );
}

export default Block;
