import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
//import config from "../app/config";
//import fetchRetryEndpoints from "../app/utils/fetchRetryEndpoints";
import BlockchainApiManager from "../app/services/blockchainApiManager";

function Home() {
  const {
    isLoading,
    error,
    data: chaininfo,
  } = useQuery("chaininfo", () => BlockchainApiManager.getInfo(), {
    refetchInterval: 1000,
  });

  //   const {
  //     isLoading,
  //     error,
  //     data: chaininfo,
  //   } = useQuery(
  //     "chaininfo",
  //     async () => {
  //       const res = await fetchRetryEndpoints(
  //         config.endpoints.state,
  //         "/v1/chain/get_info",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           retries: 2,
  //         }
  //       );

  //       if (!res.ok) throw new Error("get_info not found");

  //       return res.json();
  //     },
  //     {
  //       refetchInterval: 1000,
  //       retry: false,
  //     }
  //   );

  return (
    <div>
      <h4>Chaininfo</h4>
      {isLoading ? <Skeleton /> : null}
      {error ? <p>An error occured</p> : null}
      {chaininfo ? (
        <>
          <ul>
            <li>
              head_block_num:
              <Link to={`/block/${chaininfo.head_block_num}`}>
                {chaininfo.head_block_num}
              </Link>
            </li>
            <li>head_block_producer: {chaininfo.head_block_producer}</li>
            <li>head_block_time: {chaininfo.head_block_time}</li>
          </ul>
        </>
      ) : null}
    </div>
  );
}

export default Home;
