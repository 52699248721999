import * as React from "react";
import { Outlet } from "react-router-dom";

function DefaultLayout() {
  return (
    <div>
      <h1>App</h1>
      <React.Suspense
        fallback={<p>Waiting for suspense Outlet component...</p>}
      >
        <Outlet />
      </React.Suspense>
    </div>
  );
}

export default DefaultLayout;
