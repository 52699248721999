import { useQuery } from "react-query";
import BlockchainApiManager from "../app/services/blockchainApiManager";

const Sample = () => {
  const { data } = useQuery(
    [`sample`],
    () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log("fetched user");
          resolve({
            name: "Ringo Starr",
          });
        }, 5000);
      });
    },
    { suspense: true }
  );

  return <>Sample data {JSON.stringify(data)}</>;
};

export default Sample;
