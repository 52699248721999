import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
//import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { IndexedDb } from "./app/db/indexedDb";
import { initDB } from "react-indexed-db";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({}),
});

initDB(IndexedDb);

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SkeletonTheme color="#424e5a" highlightColor="#4b5966">
        <App />
      </SkeletonTheme>
    </QueryClientProvider>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <QueryClientProvider client={queryClient}>
//       <SkeletonTheme color="#424e5a" highlightColor="#4b5966">
//         <App />
//       </SkeletonTheme>
//     </QueryClientProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
