export const config = {
  indexedDb: {
    name: "BlockExplorerDB",
    version: 1,
    cache: false,
  },
  endpoints: {
    default: "https://wax.greymass.com",
    state: {
      "https://api-wax.eosauthority.com": 100,
      "https://wax.eosphere.io": 80,
    },
    block: {
      // "http://wax.eosdublin.io":100,
      //"https://api-wax.eosauthority.com": 100,
      // "https://api-wax.eosarabia.net": 90,
      // "https://api.waxsweden.org": 80,
      // "https://wax.greymass.com": 10,
      //"http://wax.blokcrafters.io":100,
      "http://wax.eoseoul.io": 100, //500 error
      "https://wax.eosphere.io": 80,
    },
    transaction: {
      "http://wax.eoseoul.io": 100,
      "https://wax.eosphere.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api-wax.eosarabia.net": 70,
    },
  },
};

export default config;
