import config from "../config";
import FetchManager from "./fetchManager";
import { AddBlockIndexedDb, AddTransactionIndexedDb, GetBlockIndexedDb, GetTransactionIndexedDb } from "../db/indexedDb";

const BlockchainApiManager = {
  getInfo() {
    return FetchManager.get(config.endpoints.state, {
      path: "/v1/chain/get_info",
    });
  },
  getBlock(blockId) {
    return new Promise(async (resolve, reject) => {
      if (config.indexedDb.cache) {
        const blockIndexedDb = await GetBlockIndexedDb(blockId);
        if (blockIndexedDb) {
          return resolve(blockIndexedDb.block);
        }
      }

      const block = await FetchManager.post(config.endpoints.block, {
        path: "/v1/chain/get_block",
        data: { block_num_or_id: blockId },
      });

      if (config.indexedDb.cache && block.block_num) {
        AddBlockIndexedDb(block);
      }

      return resolve(block);
    });
  },
  getTransaction(txnId) {
    return new Promise(async (resolve, reject) => {
      if (config.indexedDb.cache) {
        const transactionIndexedDb = await GetTransactionIndexedDb(txnId);
        if (transactionIndexedDb) {
          return resolve(transactionIndexedDb.transaction);
        }
      }

      const transaction = await FetchManager.post(
        config.endpoints.transaction,
        {
          path: "/v1/history/get_transaction",
          data: { id: txnId, block_num_hint: 0 },
        }
      );

      if (config.indexedDb.cache && transaction.id) {
        AddTransactionIndexedDb(transaction);
      }

      return resolve(transaction);
    });

    // return FetchManager.post(config.endpoints.transaction, {
    //   path: "/v1/history/get_transaction",
    //   data: { id: txnId, block_num_hint: 0 },
    // });
  },
};

export default BlockchainApiManager;
