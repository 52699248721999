import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import BlockchainApiManager from "../app/services/blockchainApiManager";
import Sample from "../components/sample";
import React from "react";

//import fetchRetryEndpoints from "../app/utils/fetchRetryEndpoints";
//import config from "../app/config";

function Block() {
  let params = useParams();

  // console.log(BlockchainApiManager.getBlock(174503476));

  const {
    isLoading,
    error,
    data: block,
  } = useQuery(
    [`block`, params.id],
    () => BlockchainApiManager.getBlock(params.id),
    { suspense: true }
  );

  // const {
  //   isLoading,
  //   error,
  //   data: block,
  // } = useQuery(
  //   [`block`, params.id],
  //   async () => {
  //     const res = await fetchRetryEndpoints(
  //       config.endpoints.block,
  //       "/v1/chain/get_block",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ block_num_or_id: params.id }),
  //         retries: 3,
  //       }
  //     );

  //     if (!res.ok) throw new Error("Block not found");

  //     return res.json();
  //   },
  //   {
  //     retry: false
  //   }
  // );

  return (
    <div>
      <h4>Block</h4>
      <React.Suspense
        fallback={<p>waiting for suspense sample component...</p>}
      >
        <Sample />
      </React.Suspense>

      {isLoading ? <Skeleton /> : null}
      {error ? <p>An error occured</p> : null}
      {block ? (
        <>
          <ul>
            <li>Block num: {block.block_num}</li>
            <li>producer: {block.producer}</li>
            <li>Timestamp: {block.timestamp}</li>
            <li>Number of TX / Actions: {block.transactions.length}</li>
          </ul>
          <table>
            <tbody>
              {block.transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/transaction/${transaction.trx.id}`}>
                      {transaction.trx.id}
                    </Link>
                    {/* <Link
                      href={`/transaction/[id]`}
                      as={`/transaction/${transaction.trx.id}`}
                      prefetch={false}
                    > */}
                    {/* <a>{transaction.trx.id}</a> */}
                    {/* </Link> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
}

export default Block;
