import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DefaultLayout from "./layouts/default";
import Home from "./views/home";
import Block from "./views/block";
import Transaction from "./views/transaction";

import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Home />} />
          <Route path="block/:id" element={<Block />} />
          <Route path="transaction/:id" element={<Transaction />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
