import { useIndexedDB } from "react-indexed-db";
import config from "../config";

export const IndexedDb = {
  name: `${config.indexedDb.name}`,
  version: config.indexedDb.version,
  objectStoresMeta: [
    {
      store: "block",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "id", keypath: "id", options: { unique: true } },
        { name: "block", keypath: "block", options: { unique: false } },
      ],
    },
    {
      store: "transaction",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "id", keypath: "id", options: { unique: true } },
        { name: "transaction", keypath: "transaction", options: { unique: false } },
      ],
    },
  ],
};

export const GetBlockIndexedDb = (blockId) => {
  const { getByID } = useIndexedDB("block");
  return getByID(blockId);
};

export const AddBlockIndexedDb = (block) => {
  const { add } = useIndexedDB("block");
  return add({ id: block.block_num, block: block });
};

export const GetTransactionIndexedDb = (txnId) => {
  const { getByIndex } = useIndexedDB("transaction");
  return getByIndex('id',txnId);
};

export const AddTransactionIndexedDb = (transaction) => {
  const { add } = useIndexedDB("transaction");
  return add({ id: transaction.id, transaction: transaction });
};

